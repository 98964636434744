#player-notes-container {
  padding: 5px;
  border: 1px solid #868686;
  border-radius: 5px;
  align-self: start;
}

#player-notes-container h2 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.5rem;
}

.player-notes {
  display: grid;
  grid-template-columns: 6fr 10fr 6fr;
  column-gap: 5px;
  margin-top: 5px;
  align-items: center;
}

.player-notes textarea {
  align-self: stretch;
  resize: none;
  font-size: .8rem;
  line-height: 14px;
}