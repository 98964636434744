#left-sidebar-container {
  width: 100%;
  height: 100%;
}

#left-sidebar-container>div>h3 {
  margin-top: 5px;
  margin-bottom: 3px;
  text-align: center;
  font-size: 1.2rem;
}

#left-sidebar-container>div {
  height: 100%;
}

#game-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#game-navigation>hr {
  grid-column: 1 / 3;
  width: 100%;
  margin: .3rem 0;
}

#history-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; */
  /* margin-bottom: 10px; */
}

.history-game {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-items: stretch;
  align-items: center;
  row-gap: 2px;
  column-gap: 5px;
  margin: 5px;
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.history-game:hover {
  background-color: #e6e6e6;
}

.history-game>h4 {
  margin: 0;
  font-size: 1.2rem;
  grid-column: 1 / 3;
  font-weight: bold;
}

.history-game>p {
  margin: 0;
  font-size: .8rem;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.history-game.selected {
  border-color: rgb(0, 123, 255);
  box-shadow: 0 0 5px rgb(0, 123, 255);
}