.character-list h3 {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.character-list h4 {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.character {
  width: auto;
  height: 26px;
  line-height: 0px;
  font-size: 15px;

  padding: 3px;
  border: 1px solid black;
  border-radius: 5px;

  margin-bottom: 1px;

  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  display: grid;
  grid-template-columns: 1fr 20px 1px;
  align-items: center;
  justify-content: center;
}

.character-source,
.character-display {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.character-source {
  background-color: rgb(237, 237, 237);
  color: rgb(15, 15, 15);
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}

.character-source:hover {
  background-color: rgb(212, 212, 212);
}

.character-display {
  pointer-events: all;
  display: grid;
  grid-template-columns: 1fr 20px 1px;
  align-items: center;
  justify-content: center;
}

.character-display.not-editable {
  grid-template-columns: 1fr;
}

.character.townsfolk {
  background-color: rgb(109, 158, 235);
  color: rgb(255, 255, 255);
}

.character.outsider {
  background-color: rgb(0, 255, 225);
  color: rgb(0, 0, 0);
}

.character.minion {
  background-color: rgb(255, 153, 0);
  color: rgb(0, 0, 0);
}

.character.demon {
  background-color: rgb(224, 102, 102);
  color: rgb(0, 0, 0);
}

.delete-button {
  /* width: 18px;
  height: 18px; */
  line-height: 10px;
  font-size: 10px;

  padding: 2px;
  border: 1px solid black;
  border-radius: 5px;

  /* margin-bottom: 1px; */
  margin-left: 2px;

  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;

  background-color: transparent;
  color: rgb(255, 255, 255);
}

.delete-button:hover {
  background-color: rgb(255, 141, 141);
  color: rgb(255, 255, 255);
}

.dropzone {
  border: 1px dashed rgb(53, 57, 59);
  background-color: rgb(27, 29, 30);
  color: rgb(190, 185, 176);
  outline-color: currentcolor;
  text-align: center;
}