.player-list>div:first-child {
  margin-bottom: 3px;
}

.player-list>div>h3 {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.player {
  width: auto;
  height: 26px;
  line-height: 0px;
  font-size: 15px;

  padding: 3px;
  border: 1px solid black;
  border-radius: 5px;

  margin-bottom: 1px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.player-source,
.player-display {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.player-source {
  height: 41px;
  /* line-height: 34px; */
  font-size: 28px;

  background-color: rgb(237, 237, 237);
  color: rgb(15, 15, 15);
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  justify-content: center;
}

.player-source:hover {
  background-color: rgb(212, 212, 212);
}

.player-display {
  /* background-color: rgb(15, 15, 15);
  color: rgb(237, 237, 237); */

  background-color: rgb(237, 237, 237);
  color: rgb(15, 15, 15);

  pointer-events: all;
  display: grid;
  grid-template-columns: 20px 1fr 20px 1px;
  align-items: center;
  justify-content: center;
}

.player-display-empty {
  background: transparent;
}

.player-display>.delete-button {
  color: black;
}