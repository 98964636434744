.phase-button {
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.phase-button.night {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.phase-button.night:hover {
  background-color: rgb(63, 63, 63);
}

.phase-button.day {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.phase-button.day:hover {
  background-color: rgb(212, 212, 212);
}

.event-select {
  border-radius: 5px;
  font-size: 0.9rem;
}