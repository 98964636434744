#event-list-container {
  display: flex;
  flex-direction: column;

  width: calc(100% - 10px);
  padding: 5px;
}

.event-container {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr;
}

.event-move {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: center;
  justify-self: center;

  display: flex;
  flex-direction: column;
}

.event-move>button {
  color: white;
  padding: 0 5px;
  cursor: pointer;
  font-size: 0.65rem;
}

.event-move>button:hover {
  color: rgb(196, 196, 196);
}

.event-move>button:last-child {
  margin-top: 2px;
}

.event {
  display: grid;
  grid-template-columns: 80px 160px 1fr 1fr 1fr 30px;
  grid-column-gap: 5px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.player-character-list {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  padding-bottom: 8px;
}

.player-character-list>* {
  pointer-events: none;
}

.phase-separator {
  display: grid;
  grid-template-columns: 1fr 150px 1fr;

  text-align: center;
  /* font-size: 24px; */
}

.phase-separator>hr {
  place-self: center stretch;
  margin: 0;

  border-width: 2px;
  border-color: rgb(214, 214, 214);
  opacity: 1;
}

.phase-separator>h2 {
  margin: 0;
  font-size: 1.5rem;
}

.phase-separator.good-won,
.phase-separator.good-won>hr {
  color: #2a8cff;
  border-color: #2a8cff;
}

.phase-separator.evil-won,
.phase-separator.evil-won>hr {
  color: #ff2020;
  border-color: #ff2020;
}

.event-vertical-list {
  display: flex;
  flex-direction: column;
}