#add-event-container {
  padding: 5px;
  border: 1px solid #868686;
  border-radius: 5px;
  align-self: start;

  font-size: .8rem;
}

#add-event-container.collapsed {
  padding: 5px 100px 5px 40px;
}

#add-event-container.collapsed:hover {
  cursor: pointer;
  background: #717171;
}

#add-event-container h2 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  font-size: 1.5rem;
}

/* #add-event-container>h3 {
  margin-top: 2px;
  margin-bottom: 5px;
} */

.add-event-group-outer>h3 {
  margin: 14px 5px 0 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.add-event-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5px 5px 5px;
  margin-top: 5px;

  /* border: 1px solid #868686; */
  /* border-radius: 5px; */
}

.add-event-group-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5px 5px 5px;
  margin-top: 5px;

  border: 1px solid #868686;
  border-radius: 5px;
}


.add-event-button {
  padding: 5px 15px;
  margin-right: 10px;
  margin-top: 5px;

  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}

.add-event-button:hover {
  box-shadow: 0px 0px 4px 1px white;
}