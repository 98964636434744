html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body.modal-open {
  overflow: unset !important;
  padding-right: 0 !important;
}

#main-container {
  height: 100%;
  /* margin: 5px; */
  display: grid;
  grid-template-columns: 210px 1fr 230px;
}

#left-sidebar {
  position: fixed;
  width: 210px;
  height: 100%;
  padding: 5px;

  border-right: 1px solid black;

  background: #2B2B2B;
  color: white;
}

#right-sidebar {
  position: fixed;
  width: 230px;
  right: 0;
  height: 100%;
  padding: 5px;

  border-left: 1px solid black;

  background: #2B2B2B;
  color: white;
  box-sizing: border-box;
}

#main-content {
  grid-column: 2 / 3;

  background: #5C5C5C;

  background-attachment: fixed;
  color: white;
}

#sub-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  margin: 30px 10px 0 10px;
}

/* GLOBAL UTILITY STYLES */
.row-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spaced-items {
  display: flex;
  flex-direction: row;
}

.spaced-items>*:not(:first-child) {
  margin-left: 5px;
}

.row-items-full-width {
  display: flex;
  flex-direction: row;
}

.row-items-full-width>* {
  flex: 1;
}

.row-items-full-width>*:not(:first-child) {
  margin-left: 5px;
}

.column-items-full-width {
  display: flex;
  flex-direction: column;
}

textarea {
  font-family: monospace !important;
}